import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { userLogin } from "../../actions/userAction";
import logo from '../assets/svg.svg';

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        email: "",
        password: "",
        error: false,
        loading: false,
        redirect: false,
        status: false,
        message: "",
        user: null,
        success: false,
    });

    const handleChange = (e) => {
        e.persist();
        setState((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    useEffect(() => {
        const abortController = new AbortController();
        const token = localStorage.getItem("app_token:key");
        if (token) {
            history.push("/dashboard");
        }
        return () => {
            abortController.abort();
        };
    }, [history]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = state;
        if (email && password) {
            const data = {
                email,
                password,
            };

            setState((prevState) => ({
                ...prevState,
                loading: true,
            }));

            const res = await new Promise((resolve, reject) => {
                resolve(dispatch(userLogin(data)));
            })

            console.log(res)

            if (res) {
                console.log(res)

            }

        }


        // userLogin(data)
        //     .then((doc) => {
        //         console.log(doc)

        //         if (doc.token) {
        //             setState({
        //                 email: "",
        //                 password: "",
        //                 loading: false,
        //             });

        //             localStorage.setItem("app_token:key", doc.token);

        //             if (doc.data.companyName) {
        //                 setTimeout(() => {
        //                     history.push("/dashboard");
        //                     window.location.reload();
        //                 }, 3200);
        //             } else {
        //                 setTimeout(() => {
        //                     history.push("/dashboard");
        //                     window.location.reload();
        //                 }, 3000);
        //             }
        //         } else {
        //             setState({
        //                 email: "",
        //                 password: "",
        //             });

        //             setTimeout(() => {
        //                 history.push(`/security/twofactor/${doc.data._id}`);
        //             }, 3000);
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setState({
        //             email: "",
        //             password: "",
        //             loading: false,
        //         });
        //         history.push(`/`);
        //     })
        //     .finally(() => {
        //         dispatch(getUsers());
        //     });
    };


    return (
        <section style={{ textAlign: "center" }} className="container-fluid">
            <div className="row">
                <div className="col-md-12 col-sm-12 p-0 login_bg">
                    <div className="login_bglayer">
                        <div className="top_bg">
                            <img src={logo} className="img-responsive" alt="RusselSmith Logo" />
                        </div>
                        <div className="login_box">
                            <div className="top_box">
                                <h3>Login</h3>
                            </div>
                            <div className="form_box">
                                <p className="text-danger login_error">{state.error}</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            id="email"
                                            placeholder="Enter your Email"
                                            onChange={handleChange}
                                            disabled={state.loading}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            className="form-control"
                                            type="password"
                                            id="password"
                                            placeholder="Enter your Password"
                                            onChange={handleChange}
                                            disabled={state.loading}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-block">
                                            {state.loading ? 'loading' : 'LOGIN'}
                                        </button>
                                    </div>
                                    <p className="float-right">
                                        <Link to="/forgot" className="link">Reset Password</Link>
                                    </p>
                                    <p className="float-left">
                                        <Link to="/" className="link">Home</Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;