import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap'
import { connect } from 'react-redux'
import { addItem } from '../../../actions/itemAction';

class ItemModal extends Component {
    state = {
        modal: false,
        name: "",
    }
    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }
    onSubmit = (e) => {
        e.preventDefault()
        const newItem = { name: this.state.name }
        this.props.addItem(newItem);
        this.toggle();
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Button
                        color='dark'
                        style={{ marginBottom: 20 }}
                        onClick={this.toggle} >
                        Add item to list
                    </Button>
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>
                            ADD AN ITEM
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={this.onSubmit}>
                                <FormGroup>
                                    <Label for='name'>Name of item</Label>
                                    <Input type='text' name='name' id='name' required placeholder='Add Item' onChange={this.onChange} />
                                    <Button
                                        style={{ marginTop: '10px', padding: '6px' }}
                                        className='btn btn-lg'
                                        block
                                    >Add Item
                                    </Button>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    item: state.item
})

export default connect(mapStateToProps, { addItem })(ItemModal);







