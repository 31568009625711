import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import PublicRoute from './components/config/AuthRoute';
// auth
import Login from './components/auth/Login';
import Forgot from './components/auth/Forgot';
// app
import Home from './components/app/Home';
// css
import './components/assets/authstyles.css';
import './components/assets/appstyles.css';

import { Provider } from 'react-redux';
import store from './store';

// import { loadUser } from './actions/authAction';

class App extends Component {
//   componentDidMount() {
//     store.dispatch(loadUser())
//   }
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <PublicRoute path='/' exact component={Home} />
            <PublicRoute path='/forgot' component={Forgot} />
            <PublicRoute path='/login' component={Login} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
