import axios from 'axios';
import * as types from '../types/itemsType'

export const setItemLoading = () => {
    return {
        type: types.ITEMS_LOADING
    }
}

export const getItems = () => async dispatch => {
    try{
        dispatch(setItemLoading());
        const { data } = await axios.get('https://hrm-x60k.onrender.com/api/items');
        dispatch({
            type: types.GET_ITEMS,
            payload: data.data,
        });
    } catch (error) {
        console.log(error.response);
    }
}

export const addItem = item => dispatch => {
    axios.post('/api/items', item).then(res => 
    dispatch({
        type: types.ADD_ITEM,
        payload: res.data.newItem
    }))
}

export const deleteItem = id => dispatch => {
    axios.delete(`https://hrm-x60k.onrender.com/api/items/${id}`).then(res =>
        dispatch({
            type: types.DELETE_ITEM,
            payload: res.data.item._id
        })
    )
}
