import * as types from '../types/usersType'

const initialState = {
    users: [],
    user: {},
    loading: false,
    errors: null,
    success: false,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USERS:
            return {
                ...state
            };
        default:
            return state;
    }
}
export default userReducer;