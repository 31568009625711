import React, { Component } from 'react'
import { Container, ListGroup, ListGroupItem, Button } from 'reactstrap'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AppNavbar from '../atoms/AppNavbar';
import { connect } from 'react-redux';
import { getItems, deleteItem } from '../../actions/itemAction';
import PropTypes from 'prop-types';
import ItemModal from './modals/addItemModal';


class Home extends Component {
    componentDidMount() {
        this.props.getItems();
    }
    handleDelete = (id) => {
        this.props.deleteItem(id);
    }
    render() {
        const { items } = this.props.item;
        console.log(this.props);
        return (
            <React.Fragment>
                <section>
                    <div className="dash">
                        <AppNavbar />
                        <Container>
                            <ItemModal />
                            <ListGroup>
                                <TransitionGroup className='shopping-list'>
                                    {items.map(({ _id, name }) => (
                                        <CSSTransition key={_id} timeout={500} classNames="fade">
                                            <ListGroupItem>
                                                <Button
                                                    className='remove-btn'
                                                    color='danger'
                                                    size='sm'
                                                    onClick={this.handleDelete.bind(this, _id)}>&times;
                                                </Button>
                                                {name}
                                            </ListGroupItem>
                                        </CSSTransition>
                                    ))}
                                </TransitionGroup>
                            </ListGroup>
                        </Container>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

Home.propTypes = {
    getItems: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    item: state.item
})

export default connect(mapStateToProps, { getItems, deleteItem })(Home);
