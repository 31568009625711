import * as types from '../types/usersType'
import axios from "axios";

const token = localStorage.getItem("app_token:key");

export const getUsers = () => {
  return {
    type: types.GET_USERS
  }
}

export const createUserAccount = (data) => async (dispatch) => {
  try {
    const URL = "https://hrm-x60k.onrender.com/api/user";
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: types.ADD_USER,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: types.ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const userLogin = ({ email, password }) => async (dispatch) => {
  console.log(email, password)

  try {
    const res = await axios.post("https://hrm-x60k.onrender.com/api/user/login", {
      email,
      password,
    });

    dispatch({
      type: types.LOGIN_USER,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: types.LOGIN_USER_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: types.LOGIN_USER_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: types.LOGIN_USER_ERROR,
        error: error.message,
      });
    }
  }
};
