import React, { Component } from "react";
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { forgotPassword } from "../config/config";

class Forgot extends Component {
    state = { email: '', status: false }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let payload = { email: this.state.email }
        if (this.state.email === "") {
            this.setState({ error: 'Error with Email Address' });
        } else if (this.state.email) {
            if (this.state.error) { this.setState({ error: null }) }
            forgotPassword(payload)
                .then((res) => {
                    if (res.status) {
                        this.setState({ status: res.status, msg: res.data });
                    } else { this.setState({ error: res.data }); }
                })
                .then((error) => {
                    console.log(error)
                })
        }
    }
    render() {
        return (
            <section style={{ textAlign: "center" }} className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-sm-12 p-0 login_bg">
                        <div className="login_bglayer">
                            <div className="top_bg">
                                <img src={logo} className="img-responsive" alt="RusselSmith Logo" />
                            </div>
                            <div className="login_box">
                                <div className="top_box">
                                    <h3>Reset Password</h3>
                                </div>
                                <div className="form_box">
                                    <p className="text-danger login_error">{this.state.error}</p>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                id="email"
                                                placeholder="Enter your Email"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-block"> RESET PASSWORD </button>
                                        </div>
                                        <p className="float-right">
                                            <Link to="/" className="link">Login</Link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Forgot;