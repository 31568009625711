import * as types from '../types/itemsType'

const initialState = {
    items: [],
    item: {},
    loading: false,
    errors: null,
    success: false,
}

const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ITEMS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ITEMS:
            return {
                ...state,
                loading: false,
                items: action.payload,
            };
        case types.ADD_ITEM:
            return {
                ...state,
                items: [action.payload, ...state.items]
            };
        case types.DELETE_ITEM:
            return {
                ...state,
                items: state.items.filter(item => item._id !== action.payload)
            };
        default:
            return state;
    }
}
export default itemReducer;